import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "bootstrap/dist/js/bootstrap";

import Header from "./components/Header";
import Footer from "./components/Footer";
import SuspenseComponent from "./components/Suspense";

const IndividualArticle = lazy(() => import("./pages/IndividualArticle"));

const Homepage = lazy(() => import("./pages/Homepage"));

const ECRList = lazy(() => import("./pages/entertainment-city-recommend/List"));

const ECWList = lazy(() => import("./pages/entertainment-city-walkthrough/List"));

const GWEGList = lazy(() => import("./pages/games-walkthrough/electronic-games/List"));
const GWLBList = lazy(() => import("./pages/games-walkthrough/livebet/List"));
const GWSList = lazy(() => import("./pages/games-walkthrough/sport/List"));
const GWLList = lazy(() => import("./pages/games-walkthrough/lottery/List"));
const GWESList = lazy(() => import("./pages/games-walkthrough/e-sports/List"));
const GWCList = lazy(() => import("./pages/games-walkthrough/chess/List"));

// const PGSMList = lazy(() => import("./pages/popular-games/slot-machine/List"));
// const PGBList = lazy(() => import("./pages/popular-games/baccarat/List"));
// const PGSOList = lazy(() => import("./pages/popular-games/scratch-off/List"));

// const GSPSMList = lazy(() => import("./pages/game-system-providers/slot-machine/List"));
// const GSPBList = lazy(() => import("./pages/game-system-providers/baccarat/List"));
// const GSPSBList = lazy(() => import("./pages/game-system-providers/sport-betting/List"));
// const GSPLList = lazy(() => import("./pages/game-system-providers/lottery/List"));

const Consultation = lazy(() => import("./pages/Consultation"));
const PostAds = lazy(() => import("./pages/PostAds"));
const CasinoWebsite = lazy(() => import("./pages/CasinoWebsite"));

function App() {
  return (
    <div className="min-vh-100 d-flex flex-column">
        <Header />
        <Routes>
          <Route exact path="/" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <Homepage />
            </React.Suspense>}>
          </Route>
          {/* <Route exact path="/entertainment-city-recommend" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <ECRList />
            </React.Suspense>}>
          </Route> */}
          <Route exact path="/entertainment-city-walkthrough" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <ECWList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/entertainment-city-walkthrough/:id" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <IndividualArticle />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/electronic-game" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWEGList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/livebet" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWLBList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/sport" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWSList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/lottery" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWLList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/e-sports" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWESList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/chess" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GWCList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/games-walkthrough/:id" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <IndividualArticle />
            </React.Suspense>}>
          </Route>
          {/* <Route exact path="/popular-games/slot-machines" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <PGSMList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/popular-games/baccarat" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <PGBList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/popular-games/scratch-off" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <PGSOList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/game-system-providers/slot-machines" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GSPSMList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/game-system-providers/baccarat" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GSPBList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/game-system-providers/sport-betting" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GSPSBList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/game-system-providers/lottery" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <GSPLList />
            </React.Suspense>}>
          </Route> */}
          <Route exact path="/consultation" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <Consultation />
            </React.Suspense>}>
          </Route>
          <Route exact path="/post-ads" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <PostAds />
            </React.Suspense>}>
          </Route>
          <Route exact path="/casino-website" element={
            <React.Suspense fallback={<SuspenseComponent />}>
              <CasinoWebsite />
            </React.Suspense>}>
          </Route>
        </Routes>
        <Footer />
    </div>
  );
}

export default App;