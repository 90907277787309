import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/images/header/logo.png';

export default function Header () {
    const [ gwUlIsVisible, setGwUlIsVisible ] = useState(false);
    const [ pgUlIsVisible, setPgUlIsVisible ] = useState(false);
    const [ egmUlIsVisible, setEgmUlIsVisible ] = useState(false);
    const [ currentVisitor, setCurrentVisitor ] = useState(0);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
        const btn = document.querySelector("#offcanvasRightBtn");
        if (btn) {
            btn.click();
        }
        // setCurrentVisitor(Math.floor(10 + Math.random() * 11));
    }, [location]);

    useEffect(() => {
        const generateRandomNumber = () => {
            setCurrentVisitor(Math.floor(10 + Math.random() * 11));
          };
      
          // 初始生成一次随機數
          generateRandomNumber();
      
          // 設置一個 30 秒的定時器
          const interval = setInterval(generateRandomNumber, 30000);
      
          // 在組件卸載時清除定時器
          return () => clearInterval(interval);
    }, []);

    return (
        <nav className="w-100 bg-black header d-flex align-items-center" style={{boxShadow: "1px 1px 1px 1px rgba(255, 255, 255, 0.5)"}}>
            <div className="container">
                <div className='d-flex justify-content-between'>
                    <div className="flex-shrink-1 my-2">
                        <div className="">
                            <Link to="/" className='bg-transparent p-0'>
                                <img src={logo} alt="logo" className='' style={{height: "80px"}}></img>
                            </Link>
                        </div>
                    </div>
                    <ul className="d-none d-lg-flex header-ul my-4" style={{listStyle: "none"}}>
                        <li>
                            <Link to="/">首頁</Link>
                        </li>
                        {/* <li>
                            <Link to="/entertainment-city-recommend">娛樂城推薦</Link>
                        </li> */}
                        <li>
                            <Link to="/entertainment-city-walkthrough">娛樂城攻略</Link>
                        </li>
                        <li className='position-relative cursor-pointer header-games-walkthrough'>遊戲攻略
                            <ul className="position-absolute header-games-walkthrough-subUl">
                                <li>
                                    <Link to="/games-walkthrough/electronic-game">電子遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/livebet">場中投注</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/sport">體育賽事</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/lottery">彩票遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/e-sports">電競遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/chess">棋牌遊戲</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li className='position-relative cursor-pointer header-popular-games'>熱門遊戲精選
                            <ul className="position-absolute header-popular-games-subUl">
                                <li>
                                    <Link to="/popular-games/slot-machines">老虎機推薦機台</Link>
                                </li>
                                <li>
                                    <Link to="/popular-games/baccarat">百家樂推薦遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/popular-games/scratch-off">刮刮樂推薦</Link>
                                </li>
                            </ul>
                        </li>
                        <li className='position-relative cursor-pointer header-game-system-providers'>娛樂城遊戲系統商
                            <ul className="position-absolute header-game-system-providers-subUl">
                                <li>
                                    <Link to="/game-system-providers/slot-machines">老虎機系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/baccarat">百家樂系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/sport-betting">運彩投注系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/lottery">彩票系統</Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* <li>
                            <Link to="">運彩賽事表</Link>
                        </li> */}
                        <li className=''>
                                <Link to="/consultation">詐騙諮詢</Link>
                            </li>
                            <li className=''>
                                <Link to="/post-ads">廣告刊登</Link>
                            </li>
                        <li>
                            <Link to="/casino-website">賭博網站</Link>
                        </li>
                    </ul>
                    <div className='d-flex align-items-center d-lg-none'>
                        <button type="button" className='btn bg-transparent' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            <div className='d-flex flex-column'>
                                <div style={{width: "40px", height: "2px", background: "white", marginBottom: "10px"}}></div>
                                <div style={{width: "40px", height: "2px", background: "white", marginBottom: "10px"}}></div>
                                <div style={{width: "40px", height: "2px", background: "white"}}></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className='text-end text-white me-6 pb-3' style={{fontSize: "0.9rem"}}>線上人數：{currentVisitor}</div>
            </div>
            <div class="offcanvas offcanvas-end bg-black text-white" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel"></h5>
                    <button type="button" class="btn-close text-reset bg-white" id="offcanvasRightBtn" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul style={{listStyleType: "none"}}>
                        <li className=''>
                            <Link to="/" className='bg-transparent p-0'>首頁</Link>
                        </li>
                        {/* <li className=''>
                            <Link to="/entertainment-city-recommend">娛樂城推薦</Link>
                        </li> */}
                        <li className=''>
                            <Link to="/entertainment-city-walkthrough">娛樂城攻略</Link>
                        </li>
                        <li className='position-relative cursor-pointer header-games-walkthrough d-flex flex-column justify-content-center' onClick={() => setGwUlIsVisible(prev => !prev)}>
                            <div className="d-flex align-items-center">遊戲攻略
                                <span class="material-symbols-outlined fs-2 ms-1">
                                    stat_minus_1
                                </span>
                            </div>
                            <ul className={`${gwUlIsVisible ? "d-block" : "d-none"} header-games-walkthrough-subUl`}>
                                <li>
                                    <Link to="/games-walkthrough/electronic-game">電子遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/livebet">場中投注</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/sport">體育賽事</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/lottery">彩票遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/e-sports">電競遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/games-walkthrough/chess">棋牌遊戲</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li className='position-relative cursor-pointer header-popular-games' onClick={() => setPgUlIsVisible(prev => !prev)}>
                            <div className="d-flex align-items-center">熱門遊戲精選
                                <span class="material-symbols-outlined fs-2 ms-1">
                                    stat_minus_1
                                </span>
                            </div>
                            <ul className={`${pgUlIsVisible ? "d-block" : "d-none"} header-popular-games-subUl`}>
                                <li>
                                    <Link to="/popular-games/slot-machines">老虎機推薦機台</Link>
                                </li>
                                <li>
                                    <Link to="/popular-games/baccarat">百家樂推薦遊戲</Link>
                                </li>
                                <li>
                                    <Link to="/popular-games/scratch-off">刮刮樂推薦</Link>
                                </li>
                            </ul>
                        </li>
                        <li className='position-relative cursor-pointer header-game-system-providers' onClick={() => setEgmUlIsVisible(prev => !prev)}>
                            <div className="d-flex align-items-center">娛樂城遊戲系統商
                                <span class="material-symbols-outlined fs-2 ms-1">
                                    stat_minus_1
                                </span>
                            </div>
                            <ul className={`${egmUlIsVisible ? "d-block" : "d-none"} header-game-system-providers-subUl`}>
                                <li>
                                    <Link to="/game-system-providers/slot-machines">老虎機系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/baccarat">百家樂系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/sport-betting">運彩投注系統</Link>
                                </li>
                                <li>
                                    <Link to="/game-system-providers/lottery">彩票系統</Link>
                                </li>
                            </ul>
                        </li> */}
                        <li className=''>
                            <Link to="/consultation">詐騙諮詢</Link>
                        </li>
                        <li className=''>
                            <Link to="/post-ads">廣告刊登</Link>
                        </li>
                        <li className=''>
                            <Link to="/casino-website">賭博網站</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}